import { LoanpassConfigurationsResponse } from "@elphi/types";
import { useCallback, useEffect, useRef } from "react";
import { LoadingContent } from "../../../../common-components";
import { useLoanpassWorkerHooks } from "./loanpass.hooks";
import { LoanpassMessageType } from "./loanpass.types";

export const LoanPassPricing = (props: {
  config: LoanpassConfigurationsResponse;
}) => {
  const { config } = props;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { passMessageToWorker, isListening, isLoggedIn } =
    useLoanpassWorkerHooks();

  const FULL_LOANPASS_ORIGIN = `${config.origin}/frame-redirect/${config.clientAccessId}`;

  const messageListener = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== config.origin) {
        return;
      }
      passMessageToWorker(event.data);
    },
    [passMessageToWorker, config.origin]
  );

  useEffect(() => {
    const contentWindow = iframeRef.current?.contentWindow;
    const canLogIn =
      !isLoggedIn && isListening && contentWindow && config.origin;
    if (canLogIn) {
      contentWindow.postMessage(
        {
          message: LoanpassMessageType.LogIn,
          clientAccessId: config.clientAccessId,
          emailAddress: config.email,
          password: config.password
        },
        config.origin
      );
    }
  }, [isLoggedIn, isListening, config]);

  useEffect(() => {
    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [messageListener]);

  const onLoad = useCallback(() => {
    const contentWindow = iframeRef.current?.contentWindow;
    const canLoad = contentWindow && config.origin;

    if (!canLoad) {
      return;
    }

    contentWindow.postMessage(
      { message: LoanpassMessageType.Connect },
      config.origin
    );

    contentWindow.postMessage(
      { message: LoanpassMessageType.EnableFloatRequests },
      FULL_LOANPASS_ORIGIN
    );
  }, [config.origin, FULL_LOANPASS_ORIGIN]);

  return (
    <LoadingContent isLoading={!isListening}>
      <iframe
        ref={iframeRef}
        onLoad={onLoad}
        src={FULL_LOANPASS_ORIGIN}
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </LoadingContent>
  );
};
