import { baseElphiFieldsSpecs } from "../base/spec-files/baseElphiFieldsSpecs";
import { limaSpecs } from "../lima/spec-files/limaFieldsSpecs";
import { mofinSpecs } from "../mofin/spec-files/mofinFieldSpecs";
import { DomainOwner, DynamicSchema } from "../types/dynamicSchema.types";
import { getDomainOwner } from "../utils/formCommon.utils";

export const fieldSpecsMap = {
  [DomainOwner.Elphi]: baseElphiFieldsSpecs,
  [DomainOwner.Lima]: limaSpecs,
  [DomainOwner.Mofin]: mofinSpecs
};

export const getSpecs = (): DynamicSchema["specs"] => {
  const specOwner = getDomainOwner();
  return fieldSpecsMap[specOwner];
};
