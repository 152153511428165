import { TObject, TProperties } from "@sinclair/typebox";
import { ElphiEnv } from "../../common";
import { ElphiEntityType } from "../../entities";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import {
  assetSchemas,
  dealPartySchemas,
  dealSchemas,
  partyRepationSchemas,
  partySchemas,
  propertySchemas
} from "./entity-Schemas";
import {
  EntityToSchemaMap,
  EnvToSchemasMap,
  ValidationSchemaEntity
} from "./validationSchemas.types";

const envToSchemasMap: EntityToSchemaMap = {
  [ElphiEntityType.deal]: dealSchemas,
  [ElphiEntityType.property]: propertySchemas,
  [ElphiEntityType.dealParty]: dealPartySchemas,
  [ElphiEntityType.party]: partySchemas,
  [ElphiEntityType.partyRelation]: partyRepationSchemas,
  [ElphiEntityType.asset]: assetSchemas
};

const resolveEntitySchema = (
  entity: ValidationSchemaEntity
): EnvToSchemasMap | undefined => {
  const { type } = entity;

  switch (type) {
    case ElphiEntityType.deal:
      return envToSchemasMap[type];
    case ElphiEntityType.property:
      return envToSchemasMap[type];
    case ElphiEntityType.dealParty:
      return entity.relationType && envToSchemasMap[type][entity.relationType];
    case ElphiEntityType.party:
      return entity.partyType && envToSchemasMap[type][entity.partyType];
    case ElphiEntityType.partyRelation:
      return entity.relationType && envToSchemasMap[type][entity.relationType];
    case ElphiEntityType.asset:
      return envToSchemasMap[type][entity.assetType];
    default:
      throw new Error("Invalid entity type");
  }
};

export const getEntitySchema = (r: {
  op: "create" | "update";
  entity: ValidationSchemaEntity;
}): TObject<TProperties> => {
  const { op } = r;
  const { ELPHI_ENV: env, IS_LOCAL: isLocal, LOCAL_ENV_SCHEMA } = process.env;
  if (!env) {
    throw new Error("Invalid environment name");
  }

  const localEnvSchama = (LOCAL_ENV_SCHEMA as ElphiEnv) || ElphiEnv.Localhost;
  const schemas = resolveEntitySchema(r.entity!);
  const schema = isLocal
    ? schemas?.[localEnvSchama]
    : schemas?.[env as ElphiEnv];

  if (!schema) {
    console.error("No schema found");
    throw new Error("No schema found");
  }
  return op === "create" ? OmitCreateSchema(schema) : OmitUpdateSchema(schema);
};
