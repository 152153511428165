import { pickFromEnum } from "../../../utils/enums";
import {
  baseAggregationLoanProductType,
  baseLoanPayment,
  baseLoanTermPeriodMonthCount,
  basePaymentDueDay
} from "../../base/deal/baseDeal.enumeration";

export const limaChannelType = {
  InsideSales: "Inside Sales",
  OutsideSales: "Outside Sales"
} as const;

export const limaLenderIdentifierType = {
  LimaOneCapital: "Lima One Capital, LLC",
  InvestorLendingUSA: "Investor Lending USA",
  REIServicing: "The REI Lender, LLC"
} as const;

export const limaLoanProductType = {
  ...pickFromEnum(baseAggregationLoanProductType, [
    "N51ARM",
    "N101ARM",
    "N5IO25ARM",
    "Fixed"
  ])
} as const;

export const limaLoanTermPeriodMonthCount = {
  N13: "13",
  N19: "19",
  ...pickFromEnum(baseLoanTermPeriodMonthCount, ["N24"]),
  N60: "60",
  N120: "120",
  ...pickFromEnum(baseLoanTermPeriodMonthCount, ["N360"])
} as const;

export const limaPaymentDueDay = {
  ...pickFromEnum(basePaymentDueDay, ["N10"])
} as const;

export const limaLoanPayment = {
  ...pickFromEnum(baseLoanPayment, ["PI", "IO"]),
  IOConvertedToPI: "IO Converted To P&I"
} as const;

export const limaWarehouseBank = {
  AmerisBank: "Ameris Bank",
  BancOfCalifornia: "Banc Of California",
  GeorgiaBankingCompany: "Georgia Banking Company",
  LimaOneCapital: "Lima One Capital",
  SilvergateBank: "Silvergate Bank",
  Flagstar: "Flagstar",
  DeutscheBank: "Deutsche Bank",
  JPMorgan: "JP Morgan"
} as const;
