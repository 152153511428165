import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { PosDealUserPartyView, PosPublishStatus } from "@elphi/types";
import { PublishIcon, UnpublishIcon } from "../../icons";
import {
  SIZE_FIELD,
  TableRowSizeComponent,
  TableTextCell
} from "../../table/TableRowSizeComponent";
import { posDealUserRoleOptions } from "../utils/pos.utils";

export type PosUserRowProps = {
  index: number;
  data: PosDealUserPartyView;
  publishToggleOnClick: (item: PosDealUserPartyView) => void;
};

export const PosUserRow = (props: PosUserRowProps) => {
  const { data, publishToggleOnClick } = props;

  return (
    <Flex w={"100%"} justify={"space-between"}>
      <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
        <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
          <Tooltip
            label={
              data.publishStatus === PosPublishStatus.Published
                ? "Unpublish deal"
                : "Publish the deal to the POS user to make it viewable in their borrower portal"
            }
          >
            <IconButton
              size={"sm"}
              aria-label="publish-toggle"
              icon={
                data.publishStatus === PosPublishStatus.Published ? (
                  <UnpublishIcon w={6} h={6} ml={"6px"} mt={"6px"} />
                ) : (
                  <PublishIcon w={6} h={6} ml={"6px"} mt={"6px"} />
                )
              }
              onClick={() => publishToggleOnClick(data)}
            />
          </Tooltip>
        </Flex>
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell text={data.userName} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell text={data.userEmail} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell text={data.partyName} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell text={data.userStatus} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell
          text={data.roles?.map((x) => posDealUserRoleOptions[x])?.join(", ")}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell text={data?.publishStatus} />
      </TableRowSizeComponent>
    </Flex>
  );
};
