import { LoanpassConfigurationsResponse } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { RootState } from "../../../store";
import serviceBuilder from "../../builders/api.builder";
import { taskTemplateEntityAdapter } from "../../task-template/taskTemplate.adapter";

export const taskTemplateSelector =
  taskTemplateEntityAdapter.getSelectors<RootState>(
    (state) => state.taskTemplate
  );

export const loanpassApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "loanpassApi",
  tagTypes: ["Loanpass", "Integration"],
  baseQuery: serviceBuilder.baseQuery({
    route: "/integration/pricing/loanpass"
  }),
  endpoints: (builder) => ({
    getConfigurations: builder.query<LoanpassConfigurationsResponse, void>({
      query: () => {
        return {
          url: `/configurations`,
          method: "GET"
        };
      }
    })
  })
});
