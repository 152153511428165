import { AggregationType } from "@elphi/types";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const loanProgramType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LoanProgramType?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const loanPurposeType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LoanPurposeType.fieldKey,
  validation: singleSelectValidation
});

export const loanName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LoanName.fieldKey,
  validation: singleSelectValidation
});

export const portfolioLoanIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PortfolioLoanIndicator.fieldKey,
  validation: booleanValidation
});

export const estimatedClosingDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.EstimatedClosingDate
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.ThirdParty
});

export const channel = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.Channel.fieldKey,
  validation: singleSelectValidation
});

export const operationsDepartment = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.OperationsDepartment.fieldKey,
  validation: singleSelectValidation
});

export const lenderIdentifier = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LenderIdentifier.fieldKey,
  validation: singleSelectValidation
});

export const totalPurchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalPurchasePriceAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalAssignmentFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalAssignmentFeeAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalBudgetAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalBudgetAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalAsIsAppraisedValueAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalAsIsAppraisedValueAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalSubjectToAppraisedValueAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalSubjectToAppraisedValueAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalLTVRatePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalLTVRatePercent
      ?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.Aggregation
});

export const totalLTARVRatePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalLTARVRatePercent
      ?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.Aggregation
});

export const totalTaxAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalTaxAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalHOAFeesAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalHOAFeesAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalPropertyInsurancePremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalPropertyInsurancePremiumAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalFloodInsurancePremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalFloodInsurancePremiumAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalCostAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalCostAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const requestedLoanAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.RequestedLoanAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalOutstandingLoanPayoffAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalOutstandingLoanPayoffAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const initialAdvancedFundAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InitialAdvancedFundAmount.fieldKey,
  validation: moneyValidation
});

export const totalLoanFeesAndClosingCostAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TotalLoanFeesAndClosingCostAmount
      .fieldKey,
  validation: moneyValidation
});

export const totalValuationOrPurchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .TotalValuationOrPurchasePriceAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const lesserOfLotOrPurchase = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LesserOfLotOrPurchase?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalNumberOfProperties = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalNumberOfProperties
      ?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.Aggregation
});

export const totalAdjustedMonthlyRentAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalAdjustedMonthlyRentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const cashFromBorrowerAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CashFromBorrowerAmount.fieldKey,
  validation: moneyValidation
});

export const cashToBorrowerAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CashToBorrowerAmount.fieldKey,
  validation: moneyValidation
});

export const aggregationLoanProductType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LoanProductType?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const rateLockStartDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.RateLockStartDate.fieldKey,
  validation: dateValidation
});

export const rateLockEndDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.RateLockEndDate.fieldKey,
  validation: dateValidation
});

export const wireReleaseDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireReleaseDate.fieldKey,
  validation: dateValidation
});

export const scheduledFirstPaymentDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .ScheduledFirstPaymentDate?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const paymentDueDay = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.PaymentDueDay.fieldKey,
  validation: singleSelectValidation
});

export const lastDayOfClosingMonth = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LastDayOfClosingMonth
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const loanMaturityDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LoanMaturityDate
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const loanTermPeriodMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanTermPeriodMonthCount.fieldKey,
  validation: numberValidation
});

export const loanAmortizationPeriodMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanAmortizationPeriodMonthCount
      .fieldKey,
  validation: numberValidation
});

export const loanInterestOnlyPeriodMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanInterestOnlyPeriodMonthCount
      .fieldKey,
  validation: numberValidation
});

export const interestAccrualMethodType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InterestAccrualMethodType.fieldKey,
  validation: singleSelectValidation
});

export const loanPaymentType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LoanPaymentType.fieldKey,
  validation: singleSelectValidation
});

export const prepaymentPenaltyExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .PrepaymentPenaltyExpirationDate?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const firstRateChangeDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.FirstRateChangeDate
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const indexSourceType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.IndexSourceType.fieldKey,
  validation: singleSelectValidation
});

export const capitalStructureType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CapitalStructureType.fieldKey,
  validation: singleSelectValidation
});

export const armRepriceRoundingRatePercentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ARMRepriceRoundingRatePercentType
      .fieldKey,
  validation: singleSelectValidation
});

export const armRepriceRoundingMethodType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ARMRepriceRoundingMethodType
      .fieldKey,
  validation: singleSelectValidation
});

export const armRepriceLookbackType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ARMRepriceLookbackType.fieldKey,
  validation: singleSelectValidation
});

export const armInitialFixedTermMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .ARMInitialFixedTermMonthCount?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.Aggregation
});

export const armFinalAdjustableRateTermMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .ARMFinalAdjustableRateTermMonthCount?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.Aggregation
});

export const uccFiledIndicator = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.UCCFiledIndicator.fieldKey,
  validation: booleanValidation
});

export const uccExpirationDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.UCCExpirationDate.fieldKey,
  validation: dateValidation
});

export const underwriterApprovalStatus = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.UnderwriterApprovalStatus.fieldKey,
  validation: singleSelectValidation
});

export const underwriterApprovalComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.UnderwriterApprovalComments.fieldKey,
  validation: stringValidation
});

export const submissionNotes = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.SubmissionNotes.fieldKey,
  validation: stringValidation
});

export const exceptionType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Exceptions.ExceptionType?.fieldKey,
  validation: multiSelectValidation
});

export const exceptionCommentary = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Exceptions.ExceptionCommentary
      ?.fieldKey,
  validation: stringValidation
});

export const exceptionApprovedBy = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Exceptions.ExceptionApprovedBy
      ?.fieldKey,
  validation: singleSelectValidation
});

export const titleInsuranceUnderwriter = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany
      .TitleInsuranceUnderwriter?.fieldKey,
  validation: stringValidation
});

export const titleCompanyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.FullName?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentFirstName
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentLastName
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentContactPointTelephone = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany
      .ClosingAgentContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentContactPointEmail = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany
      .ClosingAgentContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const titleCompanyAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.AddressLineText
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.AddressUnitIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.CityName?.fieldKey,
  validation: stringValidation
});

export const titleCompanyStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.StateCode?.fieldKey,
  validation: singleSelectValidation
});

export const titleCompanyPostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.PostalCode?.fieldKey,
  validation: stringValidation
});

export const gracePeriodDays = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.GracePeriodDays.fieldKey,
  validation: numberValidation
});

export const downPaymentAmount = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.DownPaymentAmount.fieldKey,
  validation: moneyValidation
});

export const creditReportFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.CreditReportFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const lenderCounselFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.LenderCounselFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const contractorReviewFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.ContractorReviewFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const feasibilityReviewFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.FeasibilityReviewFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const condoCertificationFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.CondoCertificationFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const newYorkProcessingFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.NewYorkProcessingFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const prepaidInterestAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.PrepaidInterestAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const perDiemPrepaidInterestAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .PerDiemPrepaidInterestAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});
export const propertyInsurancePremiumPaymentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyInsurancePremiumPaymentType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const totalPropertyInsurancePremiumOutstandingAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalPropertyInsurancePremiumOutstandingAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const floodInsurancePremiumPaymentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.FloodInsurancePremiumPaymentType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const totalFloodInsurancePremiumOutstandingAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalFloodInsurancePremiumOutstandingAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalInitialInsuranceEscrowAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalInitialInsuranceEscrowAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalInitialTaxEscrowAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalInitialTaxEscrowAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const interestReserveDepositAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InterestReserveDepositAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const titleCommitmentTitleEffectiveDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitleEffectiveDate
      ?.fieldKey,
  validation: dateValidation
});
export const titleCommitmentTitleExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitleExpirationDate
      ?.fieldKey,
  validation: dateValidation
});

export const titleCommitmentOwnership = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .PropertyRightsOwnershipType?.fieldKey,
  validation: singleSelectValidation
});

export const titleCommitmentTitlePolicyAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitlePolicyAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const titleCommitmentTitleVestingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitleVestingType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const titleCommitmentAllSubjectPropertiesIncludedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .AllSubjectPropertiesIncludedIndicator?.fieldKey,
  validation: booleanValidation
});

export const titleCommitmentComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .TitleCommitmentComments?.fieldKey,
  validation: stringValidation
});

export const settlementStatementComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SettlementStatementComments
      ?.fieldKey,
  validation: stringValidation
});

export const eoExpirationDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.EOExpirationDate?.fieldKey,
  validation: dateValidation
});

export const wireABARoutingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.WireABARoutingNumber?.fieldKey,
  validation: stringValidation
});

export const wireAccountNumber = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireAccountNumber?.fieldKey,
  validation: stringValidation
});

export const wireBankName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireBankName?.fieldKey,
  validation: stringValidation
});

export const wireAccountName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireAccountName?.fieldKey,
  validation: stringValidation
});

export const titleFormsComments = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.TitleFormsComments?.fieldKey,
  validation: stringValidation
});

export const netWireAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.NetWireAmount?.focused
      ?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: moneyValidation
});

export const warehouseBankType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WarehouseBankType?.fieldKey,
  validation: singleSelectValidation
});

export const warehouseBankStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.WarehouseBankStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const haircutAmount = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.HaircutAmount?.fieldKey,
  validation: moneyValidation
});

export const mailAwayIndicator = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.mailAwayIndicator?.fieldKey,
  validation: booleanValidation
});

export const closingAgentFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentFirstName
      ?.fieldKey,
  validation: stringValidation
});

export const closingAgentLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentLastName
      ?.fieldKey,
  validation: stringValidation
});

export const promissoryNoteComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PromissoryNoteComments?.fieldKey,
  validation: stringValidation
});

export const shippingCompanyType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ShippingCompanyType?.fieldKey,
  validation: singleSelectValidation
});

export const settlementStatementFileNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SettlementStatementFileNumber
      ?.fieldKey,
  validation: stringValidation
});

export const loanPackageNoteTrackingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanPackageNoteTrackingNumber
      ?.fieldKey,
  validation: stringValidation
});

export const loanPackageTrackingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanPackageTrackingNumber?.fieldKey,
  validation: stringValidation
});

export const loanPackageInternationalExecutionIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .LoanPackageInternationalExecutionIndicator?.fieldKey,
  validation: booleanValidation
});

export const achElectedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ACHElectedIndicator?.fieldKey,
  validation: booleanValidation
});

export const sellerHUDAddedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SellerHUDAddedIndicator?.fieldKey,
  validation: booleanValidation
});

export const purchaserWarrantyDeedAddedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PurchaserWarrantyDeedAddedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const fundingAgentEmailAddress = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.fundingAgentEmailAddress?.fieldKey,
  validation: emailValidation
});

export const loanPackageNoteReceivedByLenderIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .LoanPackageNoteReceivedByLenderIndicator?.fieldKey,
  validation: booleanValidation
});

export const notaryExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.NotaryExpirationDate?.fieldKey,
  validation: dateValidation
});

export const closingAnalystUserId = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ClosingAnalystUserId?.fieldKey,
  validation: stringValidation
});

export const notaryUserId = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.NotaryUserId?.fieldKey,
  validation: stringValidation
});

export const interestType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.InterestType?.fieldKey,
  validation: singleSelectValidation
});

export const mersMin = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.MERSMin?.fieldKey,
  validation: stringValidation
});

export const fundingDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.FundingDate?.fieldKey,
  validation: dateValidation
});

export const principalBalance = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.PrincipalBalance?.fieldKey,
  validation: moneyValidation
});

export const lateFeeAmount = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LateFeeAmount?.fieldKey,
  validation: moneyValidation
});

export const investorInterestRate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InvestorInterestRate?.fieldKey,
  validation: percentValidation
});
export const interestRateBuydownPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InterestRateBuydownPercent?.fieldKey,
  validation: percentValidation
});
export const originationFeePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.OriginationFeePercent?.fieldKey,
  validation: percentValidation
});
export const noteRatePercent = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.NoteRatePercent?.fieldKey,
  validation: percentValidation
});
export const loanProductType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LoanProductType?.fieldKey,
  validation: singleSelectValidation
});
export const floorPercent = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.FloorPercent?.fieldKey,
  validation: percentValidation
});
export const ceilingPercent = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.CeilingPercent?.fieldKey,
  validation: percentValidation
});
export const marginPercent = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.MarginPercent?.fieldKey,
  validation: percentValidation
});
export const interestReserveEscrowAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InterestReserveEscrowAmount
      ?.fieldKey,
  validation: moneyValidation
});
export const servicerLoanIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ServicerLoanIdentifier?.fieldKey,
  validation: stringValidation
});
export const investorLoanIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InvestorLoanIdentifier?.fieldKey,
  validation: stringValidation
});
export const servicerAccountIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ServicerAccountIdentifier?.fieldKey,
  validation: stringValidation
});
export const soldDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.SoldDate?.fieldKey,
  validation: dateValidation
});
export const servicingTransferDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ServicingTransferDate?.fieldKey,
  validation: dateValidation
});
export const servicerIdentifier = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.ServicerIdentifier?.fieldKey,
  validation: singleSelectValidation
});
export const assignmentStatus = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.AssignmentStatus?.fieldKey,
  validation: singleSelectValidation
});
export const extendedMaturityDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ExtendedMaturityDate?.fieldKey,
  validation: dateValidation
});
export const paidOffIndicator = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.PaidOffIndicator?.fieldKey,
  validation: booleanValidation
});
export const defaultStatus = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.DefaultStatus?.fieldKey,
  validation: singleSelectValidation
});
export const finalPrice = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.FinalPrice?.fieldKey,
  validation: numberValidation
});
export const investorIdentifier = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.InvestorIdentifier?.fieldKey,
  validation: singleSelectValidation
});
export const prepaymentPenaltyType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PrepaymentPenaltyType?.fieldKey,
  validation: singleSelectValidation
});
export const prepaymentPenaltyTerm = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PrepaymentPenaltyTerm?.fieldKey,
  validation: singleSelectValidation
});

export const shortTermRentalIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ShortTermRentalIndicator?.fieldKey,
  validation: booleanValidation
});

export const lockPeriod = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LockPeriod?.fieldKey,
  validation: singleSelectValidation
});
