import { LoanpassConfigurationsResponse, StatusCode } from "@elphi/types";
import { useEffect, useRef, useState } from "react";
import { responseHandler } from "../../../../apis/rtk/response.handler";
import { RTKResponse } from "../../../../apis/rtk/response.types";
import { loanpassApi } from "../../../../redux/v2/integration/loanpass/loanpass.service";
import {
  WorkerMessage,
  WorkerMessagePayload
} from "../../../../workers/loanpass.worker";
import { useElphiToast } from "../../../toast/toast.hook";
import { LoanpassMessage, LoanpassMessageType } from "./loanpass.types";

export const useLoanpassHooks = () => {
  const { errorToast } = useElphiToast();
  const [loanpassConfigs, setLoanpassConfigs] = useState<
    LoanpassConfigurationsResponse | undefined
  >();
  const [getConfigurationsApi, getConfigurationsResponse] =
    loanpassApi.useLazyGetConfigurationsQuery();

  const getLoanpassConfigs = async () => {
    await getConfigurationsApi(undefined, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.OK) {
          setLoanpassConfigs(r.data);
        } else {
          errorToast({
            title: "Failed to get Loanpass configurations",
            description: r.data.description
          });
        }
      });
  };

  return {
    loanpassConfigs,
    getLoanpassConfigs,
    getConfigurationsResponse
  };
};

export const useLoanpassWorkerHooks = () => {
  const [isListening, setIsListening] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { errorToast } = useElphiToast();

  const workerRef = useRef<Worker | null>(null);

  useEffect(() => {
    const worker = new Worker(
      new URL("../../../../workers/loanpass.worker", import.meta.url)
    );
    workerRef.current = worker;

    worker.onmessage = (event) => {
      const { type, payload } = event.data;

      if (type === WorkerMessage.IframeMessageAck) {
        manageMessages(payload);
      }
    };

    return () => {
      worker.terminate();
      workerRef.current = null;
    };
  }, []);

  //TODOL implement message handling
  const manageMessages = async (payload: WorkerMessagePayload) => {
    const { iframeData } = payload;

    switch (iframeData?.message.data.messageType) {
      case LoanpassMessageType.Listening:
        !isListening && setIsListening(true);
        break;
      case LoanpassMessageType.LoggedIn:
        setIsLoggedIn(true);
        break;
      case LoanpassMessageType.LogOut:
        setIsLoggedIn(false);
        break;
      case LoanpassMessageType.PriceLock:
        break;
      case LoanpassMessageType.SetFields:
        break;
      case LoanpassMessageType.FloatRequest:
        break;
      case LoanpassMessageType.LockLedgerUpdated:
        break;
      case LoanpassMessageType.LogInError:
      case LoanpassMessageType.LockLedgerUpdateError:
        errorToast({
          title: "Error",
          description: iframeData?.message.data.error
        });
        break;
      default:
        console.warn("[WORKER] Unhandled message", iframeData?.message.data);
    }
  };

  const passMessageToWorker = (payload: LoanpassMessage) => {
    workerRef.current?.postMessage({
      type: WorkerMessage.ProcessIframeMessage,
      payload: payload
    });
  };

  return {
    passMessageToWorker,
    isListening,
    isLoggedIn
  };
};
