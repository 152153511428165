export enum StatsigEnv {
  Localhost = "localhost",
  Demo = "demo",
  LimaTest = "lima-test",
  LimaPoc = "lima-poc",
  LimaProd = "lima-prod",
  BabarTest = "babar-test",
  EdomonTest = "edomon-test",
  ElamiaTest = "elamia-test",
  MofinTest = "mofin-test",
  MofinProd = "mofin-prod"
}
