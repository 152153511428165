import { BooleanSelectType, FieldType } from "@elphi/types";
import {
  limaFixNFlipNBridgePlusTier,
  limaPartyMilestone
} from "@elphi/types/entities/lima";
import { LimaEntityPartyFields } from "@elphi/types/entities/lima/party";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";
import {
  BaseSchemaEntityPartyFieldSpecs,
  baseSchemaEntityPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";
import {
  limaCommonPartySpecs,
  limaPartyPathsToOmitFromBase
} from "./party.fields";

type LimaEntityPartyFieldSpecs = EntityFormFieldSpecs<LimaEntityPartyFields>;

export type FullLimaSchemaEntityPartyFieldSpecs =
  Partial<BaseSchemaEntityPartyFieldSpecs> & LimaEntityPartyFieldSpecs;

const limaEntityPartyFieldSpecs: LimaEntityPartyFieldSpecs = {
  OverridePartyValidationSettingsIndicator: {
    fieldKey: ["OverridePartyValidationSettingsIndicator"],
    fieldType: FieldType.SingleSelect,
    label: "Override Party Validation Settings?",
    options: createOptionsFromMap(BooleanSelectType)
  },
  PartyMilestone: modifyFromSpec({
    fieldSpecs: baseSchemaEntityPartyFieldSpecs.PartyMilestone,
    changes: {
      options: createOptionsFromMap(limaPartyMilestone)
    }
  }),
  FixAndFlipBridgePlusTier: modifyFromSpec({
    fieldSpecs: baseSchemaEntityPartyFieldSpecs.FixAndFlipBridgePlusTier,
    changes: {
      options: createOptionsFromMap(limaFixNFlipNBridgePlusTier)
    }
  })
};

export const limaEntityPartySpecs: FullLimaSchemaEntityPartyFieldSpecs =
  mergeSpecs(
    omitFromSpec({
      obj: baseSchemaEntityPartyFieldSpecs,
      paths: limaPartyPathsToOmitFromBase
    }),
    limaCommonPartySpecs,
    limaEntityPartyFieldSpecs
  );

export const fullLimaSchemaEntityPartySpecs = createSpecWithFieldMeta({
  spec: limaEntityPartySpecs
});
