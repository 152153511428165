import { BaseEntity } from "../../db";

export enum PosDealUserRole {
  PrimaryGuarantor = "primaryGuarantor",
  Guarantor = "guarantor",
  Signatory = "signatory",
  SignatoryViaCorprateResolution = "signatoryViaCorprateResolution",
  Applicant = "applicant"
}

export enum PosPublishStatus {
  Published = "published",
  Unpublished = "unpublished"
}

export type PosDealUserParty = BaseEntity<{
  dealId: string;
  partyId: string;
  userId: string;
  status?: PosPublishStatus;
  roles?: PosDealUserRole[];
}>;
