export enum Status {
  Active = "active",
  Disabled = "disabled"
}

// this is the values from ELPHI_ENV
export enum ElphiEnv {
  Localhost = "localhost",
  Demo = "demo",
  LimaTest = "dev",
  LimaPoc = "poc",
  LimaProd = "prod",
  BabarTest = "test-babar",
  ElamiaTest = "test-elamia",
  EdomonTest = "test-edomon",
  MofinTest = "mofin-test",
  MofinProd = "mofin-prod"
}
