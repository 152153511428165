import { Text } from "@chakra-ui/react";
import { FieldType, PosDealUserPartyView, PosDealUserRole } from "@elphi/types";
import { useEffect, useState } from "react";
import { usePosDealUserPartyHooks } from "../../../hooks/posDealUserParty.hooks";
import StyledInputBuilder from "../../form-builder/InputBuilder";
import ModalContainer from "../../modal-container/ModalContainer";
import { createSelectOptions } from "../../utils/formUtils";
import { posDealUserRoleOptions } from "../utils/pos.utils";

export const PublishModal = (props: {
  show: boolean;
  onClose: () => void;
  loanIdentifier: string;
  data: PosDealUserPartyView;
}) => {
  const { handlePublishDeal, publishResponse } = usePosDealUserPartyHooks();
  const [selectedRoles, setSelectedRoles] = useState<PosDealUserRole[]>();
  useEffect(() => {
    return () => {
      setSelectedRoles(undefined);
    };
  }, [props.show]);
  const handleOnSubmit = () => {
    if (!selectedRoles?.length) {
      return;
    }
    handlePublishDeal({
      dealId: props.data.dealId,
      partyId: props.data.partyId,
      userId: props.data.userId,
      roles: selectedRoles
    });
  };
  return (
    <ModalContainer
      header={`Publish Loan: ${props.loanIdentifier} To ${props.data.partyName}`}
      isShow={props.show}
      onCloseModal={props.onClose}
      maxWidth={800}
      body={
        <>
          <StyledInputBuilder
            label={`Please select ${props.data.partyName} roles:`}
            currentValue={selectedRoles}
            fieldType={FieldType.MultiSelect}
            options={createSelectOptions(
              PosDealUserRole,
              posDealUserRoleOptions
            )}
            onChange={(e) => {
              setSelectedRoles(e.target.value);
            }}
          />
          <Text color={"red.500"} mt={4}>
            Note: Publishing allows the user to see the deal and its tasks.
          </Text>
        </>
      }
      submit={{
        showClose: true,
        closeTitle: "Cancel",
        onConfirm: handleOnSubmit,
        isLoading: publishResponse.isLoading,
        isDisabled: !selectedRoles?.length
      }}
    />
  );
};
