import {
  PosDealUserInvitationResponse,
  PosDealUserPartyView,
  PosInviteRequest,
  PosPublishRequest,
  PosUnpublishRequest
} from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import serviceBuilder from "../../builders/api.builder";

export const posDealUserApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "posDealUser",
  tagTypes: ["PosDealUser", "View"],
  baseQuery: serviceBuilder.baseQuery({
    route: "pos-deal-user"
  }),
  endpoints: (builder) => ({
    get: builder.query<{ result: PosDealUserPartyView[] }, { dealId: string }>({
      query: (r) => {
        return {
          url: `/${r.dealId}`,
          method: "GET"
        };
      }
    }),
    publish: builder.mutation<
      { id: string; request: PosPublishRequest },
      PosPublishRequest
    >({
      query: (r) => {
        return {
          url: `/publish`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (response: { id: string }, _, arg) => {
        return {
          id: response.id,
          request: arg
        };
      }
    }),
    unpublish: builder.mutation<
      { id: string; request: PosUnpublishRequest },
      PosUnpublishRequest
    >({
      query: (r) => {
        return {
          url: `/unpublish`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (response: { id: string }, _, arg) => {
        return {
          id: response.id,
          request: arg
        };
      }
    }),
    invite: builder.mutation<
      { response: PosDealUserInvitationResponse; request: PosInviteRequest },
      PosInviteRequest
    >({
      query: (r) => {
        return {
          url: `/invite`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (response: PosDealUserInvitationResponse, _, arg) => {
        return {
          response,
          request: arg
        };
      }
    })
  })
});
