import { FieldType } from "@elphi/types";
import {
  BaseCommonPartyFieldsOmit,
  LimaBasePartyFields
} from "@elphi/types/entities/lima/party";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  BaseSchemaCommonPartyFieldSpecs,
  baseSchemaCommonPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import { mergeSpecs, omitFromSpec } from "../../../utils/formsSpec.utils";

type LimaCommonPartyFieldSpecs = EntityFormFieldSpecs<LimaBasePartyFields>;

export type FullLimaCommonPartyFieldSpecs =
  Partial<BaseSchemaCommonPartyFieldSpecs> & LimaCommonPartyFieldSpecs;

const limaCommonPartyFieldSpecs: LimaCommonPartyFieldSpecs = {
  CRMId: {
    fieldKey: ["CRMId"],
    fieldType: FieldType.String,
    label: "CRM ID"
  }
};

export const limaPartyPathsToOmitFromBase: BaseCommonPartyFieldsOmit = [
  "CitizenshipResidency",
  "CreditScore",
  "CreditReportDate",
  "AuthorizedContacts",
  "NumberOfRehabProjects"
];

export const limaCommonPartySpecs: FullLimaCommonPartyFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaCommonPartyFieldSpecs,
    paths: limaPartyPathsToOmitFromBase
  }),
  limaCommonPartyFieldSpecs
);
