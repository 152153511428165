import {
  BaseEntity,
  FirestoreTimestampFieldUnionType,
  Timestamp
} from "../../../db";
import { PricingVendorType } from "../../task-types";
import { PricingEngineVendor } from "../pricingEngine.types";

export enum LoanpassStatus {
  Requested = "Requested",
  Pending = "Pending",
  Fulfilled = "Fulfilled",
  Failed = "Failed"
}

export type LoanpassPipelineRecord = Timestamp & {
  name: string;
  id: string;
};

export type LoanpassPricingEngine =
  PricingEngineVendor<PricingVendorType.Loanpass> &
    BaseEntity<{
      pipelineRecords: {
        status: LoanpassStatus;
        modifiedUID: string;
        modifiedAt: FirestoreTimestampFieldUnionType;
      };
    }>;
