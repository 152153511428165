import { MofinDealFields } from "@elphi/types/entities/mofin/deal";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  BaseSchemaDealFieldSpecs,
  baseSchemaDealSpecs
} from "../../../base/spec-files/deal/deal.fields";
import { integrationSpecs } from "../../../integrations/spec-files/integrationFieldsSpecs";
import { mergeSpecs } from "../../../utils/formsSpec.utils";

type MofinSchemaDealFieldSpecs = EntityFormFieldSpecs<MofinDealFields>;

export type FullMofinSchemaDealFieldSpecs = Partial<BaseSchemaDealFieldSpecs> &
  MofinSchemaDealFieldSpecs;

const mofinDealSpecs: FullMofinSchemaDealFieldSpecs = mergeSpecs(
  baseSchemaDealSpecs,
  integrationSpecs.deal.WireInsuranceCertificate.FundingShield.spec,
  integrationSpecs.deal.Pricing.Loanpass.spec
);

export const fullMofinDealSpecs = createSpecWithFieldMeta({
  spec: mofinDealSpecs
});
