export enum LoanpassMessageType {
  Connect = "connect",
  LogIn = "log-in",
  LogOut = "log-out",
  EnablePriceLocking = "enable-price-locking",
  EnableFloatRequests = "enable-float-requests",
  SetFields = "set-fields",
  CreatePipelineRecord = "create-pipeline-record",
  SetPipelineRecord = "set-pipeline-record",
  Listening = "listening",
  LoggedIn = "logged-in",
  LogInError = "log-in-error",
  PriceLock = "price-lock",
  FloatRequest = "float-request",
  LockLedgerUpdated = "lock-ledger-updated",
  LockLedgerUpdateError = "lock-ledger-update-error"
}

type LoampassMessagePayload<T extends LoanpassMessageType> = {
  messageType: T;
};

type LoampassErrorMessage = LoampassMessagePayload<
  LoanpassMessageType.LogInError | LoanpassMessageType.LockLedgerUpdateError
> & {
  error: string;
};
type LoampassListeningMessage =
  LoampassMessagePayload<LoanpassMessageType.Listening>;
type LoampassLoggedInMessage =
  LoampassMessagePayload<LoanpassMessageType.LoggedIn>;
type LoampassLogOutMessage = LoampassMessagePayload<LoanpassMessageType.LogOut>;
type LoampassLockLedgerUpdatedMessage =
  LoampassMessagePayload<LoanpassMessageType.LockLedgerUpdated> & {
    lockLedgerEntries: any; // TODO: Define the data type
    pipelineRecord: any; // TODO: Define the data type
  };
type LoampassFloatRequestMessage =
  LoampassMessagePayload<LoanpassMessageType.FloatRequest> & {
    product: any; // TODO: Define the data type
    pricingProfile: any; // TODO: Define the data type
  };

type SetFieldsMessage = LoampassMessagePayload<LoanpassMessageType.SetFields>;
type PriceLockMessage = LoampassMessagePayload<LoanpassMessageType.PriceLock>;

export type LoanpassMessage =
  | LoampassListeningMessage
  | LoampassLoggedInMessage
  | LoampassLogOutMessage
  | LoampassLockLedgerUpdatedMessage
  | LoampassFloatRequestMessage
  | LoampassErrorMessage
  | SetFieldsMessage
  | PriceLockMessage;
