import { Flex } from "@chakra-ui/react";
import { DevFeatureFlag } from "@elphi/utils";
import { GateKeeper } from "../../../features/gate/GateKeeper";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import { ElphiCollapseSections } from "./ElphiCollapseSections";
import { LoanPassPricing } from "./pricingEngine/LoanPassPricing";
import { useLoanpassHooks } from "./pricingEngine/loanpass.hooks";

export const LoanPassPricingPage = () => {
  const { getLoanpassConfigs, loanpassConfigs } = useLoanpassHooks();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => auth.currentUser && getLoanpassConfigs(),
    deps: [auth.currentUser]
  });

  return (
    <GateKeeper gate={DevFeatureFlag.ESD_3283_LoanPASS_integration}>
      <Flex p="8" gap={8} h={"100%"} w="100%">
        <ElphiCollapseSections />
        {loanpassConfigs && <LoanPassPricing config={loanpassConfigs} />}
      </Flex>
    </GateKeeper>
  );
};
