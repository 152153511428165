import { Flex } from "@chakra-ui/react";
import { CustomCollapse } from "../../../../common-components";
import useDealHooks from "../../../../hooks/deal.hooks";
import FormBuilder, { Section } from "../../../form-builder/FormBuilder";

const LoanPassCustomCollapseSections = (props: {
  title: string;
  sections: Section[];
}) => {
  const { title, sections } = props;
  return (
    <CustomCollapse
      options={{
        containerBorderColor: "gray.200"
      }}
      title={title}
      customBody={
        <Flex borderRadius="10px" p="10px" direction="column" gap="16px">
          <FormBuilder
            customKey={`${title}-section`}
            elphiView="form"
            onChange={() => {}}
            sections={sections}
          />
        </Flex>
      }
    />
  );
};

export const PipelineRecordInformation = () => {
  return (
    <LoanPassCustomCollapseSections
      title="Pipeline Record Information"
      sections={[]}
    />
  );
};

export const LoanInformation = () => {
  const { selectedDeal } = useDealHooks();
  const loanIdentifier = selectedDeal ? selectedDeal.LoanIdentifier : undefined;
  return (
    <LoanPassCustomCollapseSections
      title={`Loan Information - ${loanIdentifier}`}
      sections={[]}
    />
  );
};

export const PartyInformation = () => {
  return (
    <LoanPassCustomCollapseSections title="Party Information" sections={[]} />
  );
};

export const PropertyInformation = () => {
  return (
    <LoanPassCustomCollapseSections
      title="Property Information"
      sections={[]}
    />
  );
};
