import { ElphiEntityType } from "@elphi/types";
import { baseElphiFieldsSpecs } from "../../base/spec-files/baseElphiFieldsSpecs";
import { MofinSpecs } from "../types";
import { fullMofinDealSpecs } from "./deal/deal.fields";
import { fullMofinPropertySpecs } from "./property/property.fields";

export const mofinSpecs: MofinSpecs = {
  ...baseElphiFieldsSpecs,
  [ElphiEntityType.deal]: {
    entitySpecs: fullMofinDealSpecs,
    specsParts: {}
  },
  [ElphiEntityType.property]: {
    entitySpecs: fullMofinPropertySpecs,
    specsParts: {}
  }
};
