import { ElphiEnv } from "../../../common";
import { PartyRelationType } from "../../../entities";
import {
  elphiEntityToEntityRelationSchema,
  elphiEntityToIndividualRelationSchema
} from "../../base/partyRelation.typebox";
import {
  limaEntityToEntityRelationSchema,
  limaEntityToIndividualRelationSchema
} from "../../lima/partyRelation.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type PartyRelationEnvToSchemaMap = {
  [PartyRelationType.EntityToEntity]: EnvToSchemasMap;
  [PartyRelationType.EntityToIndividual]: EnvToSchemasMap;
};

export const partyRepationSchemas: PartyRelationEnvToSchemaMap = {
  [PartyRelationType.EntityToEntity]: {
    [ElphiEnv.Localhost]: elphiEntityToEntityRelationSchema,
    [ElphiEnv.EdomonTest]: elphiEntityToEntityRelationSchema,
    [ElphiEnv.BabarTest]: elphiEntityToEntityRelationSchema,
    [ElphiEnv.Demo]: elphiEntityToEntityRelationSchema,
    [ElphiEnv.MofinProd]: elphiEntityToEntityRelationSchema,
    [ElphiEnv.MofinTest]: elphiEntityToEntityRelationSchema,
    [ElphiEnv.LimaTest]: limaEntityToEntityRelationSchema,
    [ElphiEnv.LimaPoc]: limaEntityToEntityRelationSchema,
    [ElphiEnv.LimaProd]: limaEntityToEntityRelationSchema,
    [ElphiEnv.ElamiaTest]: limaEntityToEntityRelationSchema
  },
  [PartyRelationType.EntityToIndividual]: {
    [ElphiEnv.Localhost]: elphiEntityToIndividualRelationSchema,
    [ElphiEnv.EdomonTest]: elphiEntityToIndividualRelationSchema,
    [ElphiEnv.BabarTest]: elphiEntityToIndividualRelationSchema,
    [ElphiEnv.Demo]: elphiEntityToIndividualRelationSchema,
    [ElphiEnv.MofinProd]: elphiEntityToIndividualRelationSchema,
    [ElphiEnv.MofinTest]: elphiEntityToIndividualRelationSchema,
    [ElphiEnv.LimaTest]: limaEntityToIndividualRelationSchema,
    [ElphiEnv.LimaPoc]: limaEntityToIndividualRelationSchema,
    [ElphiEnv.LimaProd]: limaEntityToIndividualRelationSchema,
    [ElphiEnv.ElamiaTest]: limaEntityToIndividualRelationSchema
  }
};
