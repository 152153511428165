import { AggregationType } from "@elphi/types";

import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const propertyAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.AddressLineText
      ?.fieldKey,
  validation: stringValidation
});

export const propertyAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.AddressUnitIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const propertyCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.CityName?.fieldKey,
  validation: stringValidation
});

export const propertyCountyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.CountyName?.fieldKey,
  validation: stringValidation
});

export const propertyStateName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.StateName?.fieldKey,
  validation: singleSelectValidation
});

export const propertyStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.StateCode?.fieldKey,
  validation: singleSelectValidation
});

export const propertyPostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.PostalCode.fieldKey,
  validation: numberValidation
});

export const propertyType = buildInput({
  fieldKey: baseElphiFieldsSpecs.property?.entitySpecs.PropertyType?.fieldKey,
  validation: singleSelectValidation
});

export const financedUnitCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FinancedUnitCount?.fieldKey,
  validation: numberValidation
});

export const parcelNumber = buildInput({
  fieldKey: baseElphiFieldsSpecs.property?.entitySpecs.ParcelNumber?.fieldKey,
  validation: stringValidation
});

export const legalDescription = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.LegalDescription
      ?.fieldKey,
  validation: stringValidation
});

export const propertyLotIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.PropertyLotIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const propertyBlockIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.PropertyBlockIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const propertySubdivisionIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address
      .PropertySubdivisionIdentifier?.fieldKey,
  validation: stringValidation
});

export const propertyRightsOwnershipType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyRightsOwnershipType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const condoWarrantableIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.CondoWarrantableIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const propertyLoanPurpose = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyLoanPurpose?.fieldKey,
  validation: singleSelectValidation
});

export const purchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PurchasePriceAmount?.fieldKey,
  validation: moneyValidation
});

export const outstandingLoanPayoffAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.OutstandingLoanPayoffAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const calculatedAllocatedLoanAmount = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.AllocatedLoanAmount
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const rentalLTVPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.RentalLTVPercent?.fieldKey,
  validation: percentValidation
});

export const pricingEngineLTARVRatePercent = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations
      .PricingEngineLTARVRatePercent?.focused?.fieldKey,
  validation: percentValidation
});

export const constructionCostAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ConstructionCostAmount?.fieldKey,
  validation: moneyValidation
});

export const constructionCostComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ConstructionCostComments
      ?.fieldKey,
  validation: stringValidation
});
export const constructionHoldbackAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ConstructionHoldbackAmount
      ?.fieldKey,
  validation: moneyValidation
});
export const groundLeaseExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.GroundLeaseExpirationDate
      ?.fieldKey,
  validation: dateValidation
});
export const leaseStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.LeaseStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const originalPurchaseDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.OriginalPurchaseDate?.fieldKey,
  validation: dateValidation
});

export const originalPurchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.OriginalPurchasePriceAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const borrowerRenovationCostsAfterPurchaseAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .BorrowerRenovationCostsAfterPurchaseAmount?.fieldKey,
  validation: moneyValidation
});

export const ltcRatePercent = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.LTCRatePercent
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const annualTaxAmount = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.AnnualTaxAmount
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const annualHomeownersAssociationFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .AnnualHomeownersAssociationFeeAmount?.fieldKey,
  validation: moneyValidation
});

export const totalCostAmount = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.TotalCostAmount
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const leaseStrategy = buildInput({
  fieldKey: baseElphiFieldsSpecs.property?.entitySpecs.LeaseStrategy?.fieldKey,
  validation: singleSelectValidation
});

export const constructionBudgetAssessment = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ConstructionBudgetAssessment
      ?.fieldKey,
  validation: singleSelectValidation
});

export const contractClosingDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ContractClosingDate?.fieldKey,
  validation: dateValidation
});

export const PSAExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAExpirationDate?.fieldKey,
  validation: dateValidation
});

export const psaExtensionRequestedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAExtensionRequestedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const psaAssignmentContractIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAAssignmentContractIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const assignmentFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AssignmentFeeAmount?.fieldKey,
  validation: moneyValidation
});

export const payoffExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PayoffExpirationDate?.fieldKey,
  validation: dateValidation
});

export const appraisalValueDerivationType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .AppraisalValueDerivationType?.fieldKey,
  validation: singleSelectValidation
});

export const propertyValuationAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.PropertyValuationAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const subjectToPropertyValuationAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .SubjectToPropertyValuationAmount?.fieldKey,
  validation: moneyValidation
});

export const appraisalOrderedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalOrderedDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalCompletedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalCompletedDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalEffectiveDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalEffectiveDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalExpirationDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const conditionRatingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.ConditionRatingType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const propertyAreaValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.PropertyAreaValue
      ?.fieldKey,
  validation: numberValidation
});

export const totalBedroomCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.TotalBedroomCount
      ?.fieldKey,
  validation: numberValidation
});

export const totalBathroomCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.TotalBathroomCount
      ?.fieldKey,
  validation: numberValidation
});

export const propertyStructureBuiltYear = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyStructureBuiltYear?.fieldKey,
  validation: numberValidation
});

export const specificZoningClassification = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .SpecificZoningClassification?.fieldKey,
  validation: stringValidation
});

export const propertyZoningDescription = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyZoningDescription?.fieldKey,
  validation: stringValidation
});

export const propertyZoningComplianceRatingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyZoningComplianceRatingType?.fieldKey,
  validation: singleSelectValidation
});

export const appraisalForm1007RequiredIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .AppraisalForm1007RequiredIndicator?.fieldKey,
  validation: booleanValidation
});

export const appraisalManagementRepresentativeFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .RepresentativeFullName?.fieldKey,
  validation: stringValidation
});

export const appraisalManagementContactPointTelephoneValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const appraisalManagementContactPointEmailValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .ContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const appraisalManagementCompanyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .AppraisalManagementCompanyName?.fieldKey,
  validation: stringValidation
});

export const propertyAccessInformation = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyAccessInformation?.fieldKey,
  validation: stringValidation
});

export const inspectionInitialRequestDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionInitialRequestDate?.fieldKey,
  validation: dateValidation
});

export const inspectionReportDueDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionReportDueDate?.fieldKey,
  validation: dateValidation
});

export const inspectionOrderType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionOrderType?.fieldKey,
  validation: singleSelectValidation
});

export const inspectorFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectorFullName?.fieldKey,
  validation: stringValidation
});

export const inspectionOrderReceivedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionOrderReceivedDate?.fieldKey,
  validation: dateValidation
});

export const inspectionOrderProcessedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionOrderProcessedDate?.fieldKey,
  validation: dateValidation
});

export const inspectionDocumentsReceivedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionDocumentsReceivedDate?.fieldKey,
  validation: dateValidation
});

export const inspectionInitialDelayReasonStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionInitialDelayReasonStatusType?.fieldKey,
  validation: multiSelectValidation
});

export const allocatedLoanAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AllocatedLoanAmount?.fieldKey,
  validation: multiSelectValidation
});
