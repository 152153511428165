import { LimaEntityParty } from "@elphi/types/entities/lima/party";
import { FeatureFlag } from "@elphi/utils";
import { OnChangeInput } from "../../../../../components/form-builder/FormBuilder";
import {
  getTaxIdentifierFormatter,
  getTaxIdentifierValidator
} from "../../../../../components/utils/partyUtils";
import { operationsDepartment } from "../../../base/inputs/deal.inputs";
import {
  backgroundReportDate,
  backgroundReportExpirationDate,
  businessIncorporationStateCode,
  businessIncorporationStateName,
  businessRegistrationStates,
  entityFullName,
  entityNotes,
  entityType,
  evidenceOfGoodStandingExpirationDate,
  fixAndFlipBridgePlusTier,
  groupId,
  newConstructionEligibilityIndicator,
  partyContactPointEmailValue,
  partyContactPointTelephoneValue,
  realEstateOperationType,
  representativeCreditScore,
  taxpayerIdentifierType,
  taxpayerIdentifierValue
} from "../../../base/inputs/party.inputs";
import { addRuleToInput, buildSection } from "../../../utils/formBuilder.utils";
import {
  crmId,
  overridePartyValidationSettingsIndicator
} from "../../inputs/party.inputs";
import { limaSpecs } from "../../spec-files/limaFieldsSpecs";
import { LimaSections } from "../../types";

const entityTaxIdentifierSection = (r: {
  state: Partial<LimaEntityParty>;
  onChange: (v: OnChangeInput) => void;
  options?: {
    gates?: Partial<{ [key in FeatureFlag]: boolean }>;
  };
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Identifier",
    inputs: [
      taxpayerIdentifierType,
      addRuleToInput({
        input: taxpayerIdentifierValue,
        state: r.state,
        rules: [
          {
            field: "formatter",
            formatter: () =>
              getTaxIdentifierFormatter(r.state?.TaxpayerIdentifierType)
          },
          {
            field: "validation",
            validation:
              getTaxIdentifierValidator(r.state?.TaxpayerIdentifierType) ||
              (() => false)
          }
        ]
      }),
      addRuleToInput({
        input: groupId,
        rules: [
          {
            field: "isHidden",
            predicate: (_) =>
              !r.options?.gates?.esd_2826_organization_management
          }
        ],
        state: r.state
      }),
      overridePartyValidationSettingsIndicator
    ],
    fieldSpecs: limaSpecs.party.entitySpecs
  });

const entityInfoSection = (r: {
  state: Partial<LimaEntityParty>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Entity Info",
    inputs: [
      entityType,
      entityFullName,
      partyContactPointEmailValue,
      partyContactPointTelephoneValue,
      representativeCreditScore,
      businessIncorporationStateName,
      businessIncorporationStateCode,
      businessRegistrationStates,
      fixAndFlipBridgePlusTier,
      newConstructionEligibilityIndicator,
      realEstateOperationType,
      operationsDepartment,
      entityNotes,
      crmId,
      backgroundReportDate,
      backgroundReportExpirationDate,
      evidenceOfGoodStandingExpirationDate
    ],
    fieldSpecs: limaSpecs.party.entitySpecs
  });

export const entityPartySectionBuilders: LimaSections["party"] = {
  entityTaxIdentifierSection,
  entityInfoSection
};
