import {
  Deal,
  DocumentTaskFields,
  FirestoreTimestampFieldUnionType,
  IntegrationType,
  IntegrationTypeLabels,
  KeyClosingTaskType,
  Party,
  Property,
  Task,
  TaskStatusType,
  TaskType
} from "@elphi/types";
import { fromFirebaseIdToLabel } from "../../utils/common";

import { EMPTY, NOT_AVAILABLE } from "../../constants/common";
import { printDateTime } from "../../firebase/firebase.utils";
import { partyName } from "../party/utils/printUtils";
import { propertyAddress } from "../property/utils/printUtils";
import { taskTemplateTypeOptions } from "./task-template/templates/task.template";

export enum TaskFileStatus {
  Uploaded = "Uploaded",
  NotUploaded = "Not-Uploaded"
}

export const EMPTY_ORDER_STATUS_TEXT: string = "Missing Order Status";
export const NOT_RELEVANT_ORDER_STATUS_TEXT: string = "-";

export const truncateUserRoles = (roles: string[]) => {
  if (roles.length !== 0) {
    return `${fromFirebaseIdToLabel(roles[0])} + ${roles.length - 1}`;
  }
  return NOT_AVAILABLE;
};

export const createTooltipForUserRoles = (roles: string[]) => {
  if (roles.length !== 0) {
    return `${roles.map((role) => ` ${fromFirebaseIdToLabel(role)}`)}`;
  }
  return EMPTY;
};

export const getTaskTypeText = (
  taskType?: TaskType,
  integrationType?: IntegrationType
): string => {
  if (!taskType) {
    return NOT_AVAILABLE;
  }

  if (taskType !== TaskType.Integration) {
    return taskTemplateTypeOptions[taskType];
  }

  if (!integrationType) {
    return NOT_AVAILABLE;
  }

  const integrationTypeLabel =
    mapIntegrationTypeLabels(integrationType) || integrationType;
  return `${
    taskTemplateTypeOptions[TaskType.Integration]
  } - ${integrationTypeLabel}`;
};

export const getKeyClosingTaskTypeFromTask = (task: Task): "Yes" | "No" => {
  const kctResult = getKeyClosingTaskType(task.keyClosingTaskType);
  return kctResult;
};

export const getDocumentStatusFromTask = (task: Task) => {
  return getDocumentStatus(task?.documents || {});
};

export const getGenerationTextFromTask = (
  task: Partial<Task>,
  memoEntityType: string
): string => {
  return task.autoGenerate === "never"
    ? "manual"
    : !!task.entityType && task.generation === memoEntityType
    ? "latest"
    : "old";
};
export const getTaskStatusFromTask = (task: Task) => {
  return getTaskStatus(task?.taskStatus);
};

export const getKeyClosingTaskType = (type: KeyClosingTaskType | "") => {
  return !!type &&
    [KeyClosingTaskType.Required, KeyClosingTaskType.Optional].includes(type)
    ? "Yes"
    : "No";
};

export const getTaskStatus = (taskStatus?: string) => {
  return !taskStatus ? TaskStatusType.Created : taskStatus;
};

export const getDocumentStatus = (documentsTask: DocumentTaskFields) => {
  return Object.keys(documentsTask || {}).length
    ? TaskFileStatus.Uploaded
    : TaskFileStatus.NotUploaded;
};

export const getPropertyAddress = (property: Property | undefined) => {
  if (!!property) {
    return propertyAddress(property);
  }
  return NOT_AVAILABLE;
};

export const getDealIdentifier = (deal: Deal | "" | 0 | undefined) => {
  if (!!deal) {
    return deal.LoanIdentifier;
  }
  return NOT_AVAILABLE;
};

export const getPartyName = (party: Party | undefined | null) => {
  if (!!party) {
    return partyName(party);
  }
  return NOT_AVAILABLE;
};

export const getModifiedAt = (task: {
  modifiedAt: FirestoreTimestampFieldUnionType;
}) => printDateTime(task.modifiedAt) || NOT_AVAILABLE;

export const mapIntegrationTypeLabels = (
  integrationType: IntegrationType
): string => {
  const mapper: IntegrationTypeLabels = {
    [IntegrationType.CreditReport]: "Credit Report",
    [IntegrationType.WireInsuranceCertificate]: "Wire Insurance Certificate",
    [IntegrationType.PropertyReport]: "Property Report",
    [IntegrationType.FloodCertificate]: "Flood Certification",
    [IntegrationType.RentalData]: "Rental Data",
    [IntegrationType.Pricing]: "Pricing"
  };
  return mapper[integrationType];
};
