import { Deal, IndividualParty, LabelValue, Property } from "@elphi/types";
import { InputBuilderSpecs, OnChangeInput } from "../form-builder/FormBuilder";
import { BooleanSelectType } from "../form-builder/fieldFormat.types";

export const booleanOptions = Object.keys(BooleanSelectType).map((v) => {
  return {
    label: BooleanSelectType[v],
    value: BooleanSelectType[v]
  };
});

export const createOptionsFromMap = (e: { [x: string]: string }) => {
  return Object.keys(e).map((v) => {
    return {
      label: e[v],
      value: e[v]
    };
  });
};

export const createOptionsFromEnumSeparateLabels = (
  e: { [x: string]: string },
  l: { [x: string]: string }
) => {
  return Object.keys(e).map((v) => {
    return {
      label: l[v],
      value: e[v]
    };
  });
};
export const createOptionsFromEnumAllowUndefined = (e: {
  [x: string]: string;
}) => {
  return Object.keys(e).map((v) => {
    return {
      label: v,
      value: e[v]
    };
  });
};

export const createSelectOptions = <T extends { [key: string]: string }>(
  enumType: T,
  optionMap: { [key in T[keyof T]]: string }
) =>
  Object.values(enumType).map((value) => ({
    value,
    label: optionMap[value]
  }));

export const createOptionsFromSelectedEnum = <E extends Record<string, string>>(
  enumObject: E,
  selectedKeys: (keyof E)[]
) => {
  return selectedKeys.map(
    (key): LabelValue => ({
      label: String(key),
      value: enumObject[key]
    })
  );
};

export type ExceptionBuilderProps = {
  exceptionInput: InputBuilderSpecs;
  state: Partial<Deal | Property | IndividualParty>;
  exceptionPathName: string;
  collectionPathName?: string;
  isHidden?: boolean;
  onChange?: (v: OnChangeInput) => void;
};
