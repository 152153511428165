import { removeEmpty } from "@elphi/utils/src/common.utils";
import Fuse from "fuse.js";
import { useEffect, useMemo, useState } from "react";
import SearchComponent from "../../SearchComponent";
import {
  DropDownIndicatorFirstControl,
  IndicatorsContainer
} from "../../form-builder/InputBuilder";
import { DisabledOptionTooltip } from "../../rolodex/service-provider/wizard/search/ServiceProviderSearch";
import { DEFAULT_FUSE_CONFIG, useFuzzyHooks } from "./fuzzy.hooks";
import { FuzzySearchSelectProps } from "./fuzzySearch.types";

export const FuzzySearchSelect = <E extends object>(
  props: FuzzySearchSelectProps<E>
) => {
  const [dataSet, setDataSet] = useState<E[]>([]);
  const { query, onInputChange, debouncedQuery } = useFuzzyHooks({
    ...props
  });

  useEffect(() => {
    const data = Object.values(props.state.entities).filter(removeEmpty) || [];
    setDataSet(data);
  }, [props.state.entities]);

  const instance = useMemo(() => {
    return new Fuse(
      dataSet,
      props.configuration
        ? { ...props.configuration }
        : { ...DEFAULT_FUSE_CONFIG }
    );
  }, [dataSet]);

  useEffect(() => {
    debouncedQuery && props.searchApi({ query: debouncedQuery }, false);
  }, [debouncedQuery]);

  const options = useMemo(() => {
    if (!debouncedQuery) {
      return dataSet.map((d) => props.buildOption(d));
    }
    const result = instance.search(debouncedQuery);
    const resultOptions = result.map((result) =>
      props.buildOption(result.item)
    );
    return props.defaultOptions
      ? props.defaultOptions.concat(resultOptions)
      : resultOptions;
  }, [dataSet, debouncedQuery, props.searchResponse.data?.nextCursor]);

  const fetchMore = () => {
    props.searchApi(
      {
        query: debouncedQuery,
        cursor:
          props.state?.searchCursor?.query?.[debouncedQuery]?.nextCursor ||
          undefined
      },
      false
    );
  };

  return (
    <SearchComponent
      filterOption={props.filterOption || (() => true)}
      query={query}
      isReadOnly={props.isReadOnly}
      fieldType={props.fieldType}
      options={options}
      onInputChange={onInputChange}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      hasMore={props.state?.searchCursor?.query?.[debouncedQuery]?.hasMore}
      fetchMore={fetchMore}
      isLoading={props.searchResponse.isLoading || props.isLoading || false}
      label={props.label}
      labelPosition={"placeHolder"}
      wrapperStyle={props.wrapperStyle}
      customComponent={{
        Option: DisabledOptionTooltip,
        Control: DropDownIndicatorFirstControl,
        IndicatorsContainer: IndicatorsContainer
      }}
    />
  );
};
