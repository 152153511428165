import { Flex } from "@chakra-ui/react";
import {
  LoanInformation,
  PartyInformation,
  PipelineRecordInformation,
  PropertyInformation
} from "./sections/ElphiLoanPassSections";

export const ElphiCollapseSections = () => {
  return (
    <Flex direction="column" gap="2">
      <PipelineRecordInformation />
      <LoanInformation />
      <PartyInformation />
      <PropertyInformation />
    </Flex>
  );
};
