import { LoanpassMessage } from "../components/pricing/loanPass/pricingEngine/loanpass.types";

export type WorkerMessagePayload = {
  iframeData: {
    message: {
      data: LoanpassMessage;
    };
  };
};

export enum WorkerMessage {
  ProcessIframeMessage = "processIframeMessage",
  IframeMessageAck = "iframeMessageAck"
}

self.addEventListener("message", (event: MessageEvent) => {
  const { type, payload } = event.data;
  if (type !== WorkerMessage.ProcessIframeMessage) {
    return;
  }

  const postMessagePayload: WorkerMessagePayload = {
    iframeData: {
      message: {
        data: {
          messageType: payload.message,
          ...payload
        }
      }
    }
  };

  self.postMessage({
    type: WorkerMessage.IframeMessageAck,
    payload: postMessagePayload
  });
});
