import { ElphiEnv } from "../../../common";
import { DealPartyRelationType } from "../../../entities";
import {
  elphiDealEntitySchema,
  elphiDealIndividualSchema
} from "../../base/dealParty.typebox";
import {
  limaDealEntitySchema,
  limaDealIndividualSchema
} from "../../lima/dealParty.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type DealPartyEnvToSchemaMap = {
  [DealPartyRelationType.DealEntity]: EnvToSchemasMap;
  [DealPartyRelationType.DealIndividual]: EnvToSchemasMap;
};

export const dealPartySchemas: DealPartyEnvToSchemaMap = {
  [DealPartyRelationType.DealEntity]: {
    [ElphiEnv.Localhost]: elphiDealEntitySchema,
    [ElphiEnv.EdomonTest]: elphiDealEntitySchema,
    [ElphiEnv.BabarTest]: elphiDealEntitySchema,
    [ElphiEnv.Demo]: elphiDealEntitySchema,
    [ElphiEnv.MofinProd]: elphiDealEntitySchema,
    [ElphiEnv.MofinTest]: elphiDealEntitySchema,
    [ElphiEnv.LimaTest]: limaDealEntitySchema,
    [ElphiEnv.LimaPoc]: limaDealEntitySchema,
    [ElphiEnv.LimaProd]: limaDealEntitySchema,
    [ElphiEnv.ElamiaTest]: limaDealEntitySchema
  },
  [DealPartyRelationType.DealIndividual]: {
    [ElphiEnv.Localhost]: elphiDealIndividualSchema,
    [ElphiEnv.EdomonTest]: elphiDealIndividualSchema,
    [ElphiEnv.BabarTest]: elphiDealIndividualSchema,
    [ElphiEnv.Demo]: elphiDealIndividualSchema,
    [ElphiEnv.MofinProd]: elphiDealIndividualSchema,
    [ElphiEnv.MofinTest]: elphiDealIndividualSchema,
    [ElphiEnv.LimaTest]: limaDealIndividualSchema,
    [ElphiEnv.LimaPoc]: limaDealIndividualSchema,
    [ElphiEnv.LimaProd]: limaDealIndividualSchema,
    [ElphiEnv.ElamiaTest]: limaDealIndividualSchema
  }
};
