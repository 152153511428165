import { ElphiEnv } from "../../../common";
import { AssetType } from "../../../entities";
import { elphiAccountAssetSchema } from "../../base/asset.typebox";
import { limaAssetSchema } from "../../lima/asset.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type AssetEnvToSchemaMap = {
  [AssetType.Account]: EnvToSchemasMap;
};

export const assetSchemas: AssetEnvToSchemaMap = {
  [AssetType.Account]: {
    [ElphiEnv.Localhost]: elphiAccountAssetSchema,
    [ElphiEnv.EdomonTest]: elphiAccountAssetSchema,
    [ElphiEnv.BabarTest]: elphiAccountAssetSchema,
    [ElphiEnv.Demo]: elphiAccountAssetSchema,
    [ElphiEnv.MofinProd]: elphiAccountAssetSchema,
    [ElphiEnv.MofinTest]: elphiAccountAssetSchema,
    [ElphiEnv.LimaTest]: limaAssetSchema,
    [ElphiEnv.LimaPoc]: limaAssetSchema,
    [ElphiEnv.LimaProd]: limaAssetSchema,
    [ElphiEnv.ElamiaTest]: limaAssetSchema
  }
};
