import { ElphiEnv } from "../../../common";
import { elphiDealSchema } from "../../base/deal.typebox";
import { limaDealSchema } from "../../lima/deal.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type DealEnvToSchemaMap = EnvToSchemasMap;
export const dealSchemas: DealEnvToSchemaMap = {
  [ElphiEnv.Localhost]: elphiDealSchema,
  [ElphiEnv.EdomonTest]: elphiDealSchema,
  [ElphiEnv.BabarTest]: elphiDealSchema,
  [ElphiEnv.Demo]: elphiDealSchema,
  [ElphiEnv.MofinProd]: elphiDealSchema,
  [ElphiEnv.MofinTest]: elphiDealSchema,
  [ElphiEnv.LimaTest]: limaDealSchema,
  [ElphiEnv.LimaPoc]: limaDealSchema,
  [ElphiEnv.LimaProd]: limaDealSchema,
  [ElphiEnv.ElamiaTest]: limaDealSchema
};
