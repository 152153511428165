import { MofinPropertyFields } from "@elphi/types/entities/mofin/property";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  BaseSchemaPropertyFieldSpecs,
  baseSchemaPropertySpecs
} from "../../../base/spec-files/property/property.fields";
import { integrationSpecs } from "../../../integrations/spec-files/integrationFieldsSpecs";
import { mergeSpecs } from "../../../utils/formsSpec.utils";

type MofinSchemaPropertyFieldSpecs = EntityFormFieldSpecs<MofinPropertyFields>;

export type FullMofinSchemaPropertyFieldSpecs =
  Partial<BaseSchemaPropertyFieldSpecs> & MofinSchemaPropertyFieldSpecs;

const mofinDealSpecs: FullMofinSchemaPropertyFieldSpecs = mergeSpecs(
  baseSchemaPropertySpecs,
  integrationSpecs.property.FloodCertification.ServiceLink.spec
);

export const fullMofinPropertySpecs = createSpecWithFieldMeta({
  spec: mofinDealSpecs
});
