import { AggregationType } from "@elphi/types";
import { stringValidation } from "../../../../../components/utils/validationUtils";
import { buildInput } from "../../../utils/formBuilder.utils";
import { integrationSpecs } from "../../spec-files/integrationFieldsSpecs";

const { spec } = integrationSpecs.deal.Pricing.Loanpass;
export const pipelineRecordId = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.pipelineRecordId?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const pipelineRecordName = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.pipelineRecordName?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const investorName = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.investorName?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const productName = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.productName?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const scenario = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.scenario?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const loanPurpose = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.loanPurpose?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const propertyType = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.propertyType?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const stateCode = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.stateCode?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const appraisedValue = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.appraisedValue?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const decisionCreditScore = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.decisionCreditScore?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const citizenshipType = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.citizenshipType?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const mortgageLatePayments = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.mortgageLatePayments
      ?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const baseLoanAmount = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.baseLoanAmount?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const baseRate = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.baseRate?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const adjustedRate = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.adjustedRate?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const adjustedPrice = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.adjustedPrice?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const priceAdjustments = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.priceAdjustments?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const lockStatus = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.lockStatus?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const requestDate = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.requestDate?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const expirationDate = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.expirationDate?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const lockPeriod = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.lockPeriod?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const prepaymentPenaltyTerm = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.prepaymentPenaltyTerm
      ?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const stipulations = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.stipulations?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const totalLLPA = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing.Loanpass.totalLLPA?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
