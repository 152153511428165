import { AggregationType } from "@elphi/types";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { limaSpecs } from "../spec-files/limaFieldsSpecs";

export const employeeLoanIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.EmployeeLoanIndicator.fieldKey,
  validation: booleanValidation
});

export const chargentTransactionIdentifier = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ChargentTransactionIdentifier?.fieldKey,
  validation: stringValidation
});

export const chargentGatewayIdentifier = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ChargentGatewayIdentifier?.fieldKey,
  validation: stringValidation
});

export const chargentCollectionAmount = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ChargentCollectionAmount?.fieldKey,
  validation: moneyValidation
});

export const valuationFeesCollectedBySalesIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.ValuationFeesCollectedBySalesIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const appraisalNotes = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.AppraisalNotes?.fieldKey,
  validation: stringValidation
});

export const crmId = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.CRMId.fieldKey,
  validation: stringValidation
});

export const initialLTVRatePercent = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.InitialLTVRatePercent?.fieldKey,
  validation: percentValidation
});

export const debtServiceCoverageRatioPercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .DebtServiceCoverageRatioPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const totalAnnualCapitalExpenditure = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.aggregations.TotalAnnualCapitalExpenditure
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const monthlyPaymentOfCapitalExpenditure = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.aggregations.MonthlyPaymentOfCapitalExpenditure
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const amortizationType = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.AmortizationType.fieldKey,
  validation: singleSelectValidation
});

export const noteRatePercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.NoteRatePercent?.focused
      ?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const lastPaymentDueDate = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LastPaymentDueDate.fieldKey,
  validation: dateValidation
});

export const loanAfterInterestOnlyPeriodMonthCount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.LoanAfterInterestOnlyPeriodMonthCount.fieldKey,
  validation: numberValidation
});

export const totalMonthlyPITIPaymentAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.TotalMonthlyPITIPaymentAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const monthlyPrincipalInterestPaymentAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .MonthlyPrincipalInterestPaymentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const initialMonthlyInterestOnlyPaymentAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .InitialMonthlyInterestOnlyPaymentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const fullyDrawnInterestOnlyPayment = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.FullyDrawnInterestOnlyPayment
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const monthlyTaxInsurancePaymentAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .MonthlyTaxInsurancePaymentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const monthlyHomeownersAssociationAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .MonthlyHomeownersAssociationAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const executionType = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ExecutionType.fieldKey,
  validation: singleSelectValidation
});

export const prepaymentPenaltyType = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.PrepaymentPenaltyType
      ?.focused?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const recourseType = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.RecourseType.fieldKey,
  validation: singleSelectValidation
});

export const armRepriceFrequencyType = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.aggregations.ARMRepriceFrequencyType?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.Aggregation
});

export const margin = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.Margin?.focused?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const floorPercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.FloorPercent?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const lifetimeCapPercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.LifetimeCapPercent?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const lateFeePercent = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LateFeePercent.fieldKey,
  validation: percentValidation
});

export const grossSpreadPercent = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.GrossSpreadPercent.fieldKey,
  validation: percentValidation
});

export const letterOfIntentSignedDate = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LetterOfIntentSignedDate.fieldKey,
  validation: dateValidation
});

export const propertyManagementFullName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.PropertyManagement.FullName.fieldKey,
  validation: stringValidation
});

export const propertyManagementRepresentativeFullName = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyManagement.RepresentativeFullName
      .fieldKey,
  validation: stringValidation
});

export const propertyManagementFullAddressName = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyManagement.FullAddressName.fieldKey,
  validation: stringValidation
});

export const propertyManagementContactPointTelephoneValue = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyManagement.ContactPointTelephoneValue
      .fieldKey,
  validation: stringValidation
});

export const propertyManagementContactPointEmailValue = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyManagement.ContactPointEmailValue
      .fieldKey,
  validation: emailValidation
});

export const propertyManagementComments = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyManagement.PropertyManagementComments
      .fieldKey,
  validation: stringValidation
});

export const programDirectorApprovalIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.ProgramDirectorApprovalIndicator.fieldKey,
  validation: stringValidation
});

export const programDirectorComments = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ProgramDirectorComments.fieldKey,
  validation: stringValidation
});

export const creditMemoDraftedDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.creditMemoDraftedDate
      .fieldKey,
  validation: dateValidation
});

export const creditMemoApprovedDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.creditMemoApprovedDate
      .fieldKey,
  validation: dateValidation
});

export const internalSubmissionDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.internalSubmissionDate
      .fieldKey,
  validation: dateValidation
});

export const internalApprovalDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.internalApprovalDate
      .fieldKey,
  validation: dateValidation
});

export const externalSubmissionDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.externalSubmissionDate
      .fieldKey,
  validation: dateValidation
});

export const externalApprovalDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.externalApprovalDate
      .fieldKey,
  validation: dateValidation
});

export const lOIIssuedDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.lOIIssuedDate.fieldKey,
  validation: dateValidation
});

export const lOIAcceptedDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.CreditReviewCommittee.lOIAcceptedDate.fieldKey,
  validation: dateValidation
});

export const quoteId = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.QuoteId?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const validationErrors = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.ValidationErrors?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const ltvEligibility = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.LtvEligibility?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineWarnings = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.PricingEngineWarnings
      ?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineOverrides = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.PricingEngineOverrides
      ?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const feeLLPAs = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.FeeLLPAs?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const rateLLPAs = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.RateLLPAs?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const internalL1CRefinanceIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.InternalL1CRefinanceIndicator
      ?.focused?.fieldKey,
  validation: booleanValidation,
  calculationType: AggregationType.Aggregation
});

export const limaRateToBorrowerPercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.LimaRateToBorrowerPercent
      ?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const limaOriginationFeeToBorrowerPercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .LimaOriginationFeeToBorrowerPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineTotalLoanToAfterRepairValueOverridePercent =
  buildInput({
    fieldKey:
      limaSpecs.deal?.entitySpecs.quote.aggregations
        .PricingEngineTotalLoanToAfterRepairValueOverridePercent?.focused
        ?.fieldKey,
    validation: percentValidation,
    calculationType: AggregationType.ThirdParty
  });

export const pricingEngineTotalBlendedLoanToCostOverridePercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineTotalBlendedLoanToCostOverridePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineTotalPurchaseLoanToCostOverridePercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineTotalPurchaseLoanToCostOverridePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineNoteRateOverridePercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineNoteRateOverridePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineMaxLoanToAfterRepairValuePercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineMaxLoanToAfterRepairValuePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineMaxBlendedLoanToCostPercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineMaxBlendedLoanToCostPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineMaxPurchaseLoanToCostPercent = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineMaxPurchaseLoanToCostPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const blendedLTCAdjuster = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.BlendedLTCAdjuster?.focused
      ?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const purchaseLTCAdjuster = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.PurchaseLTCAdjuster?.focused
      ?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const arvAdjuster = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.ARVAdjuster?.focused
      ?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingInfo = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.PricingInfo?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const campaignCode = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.CampaignCode?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const campaignRateIncentive = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.CampaignRateIncentive
      ?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const campaignFeeIncentive = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.CampaignFeeIncentive?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const campaignLeverageIncentive = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.CampaignLeverageIncentive
      ?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});
export const campaignExpirationDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.CampaignExpirationDate
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.ThirdParty
});

export const interestReserveEscrowOverrideMonthCount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.InterestReserveEscrowOverrideMonthCount
      ?.fieldKey,
  validation: numberValidation
});

export const interestReserveEscrowOverrideAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.InterestReserveEscrowOverrideAmount?.fieldKey,
  validation: moneyValidation
});

export const processingFeeOverride = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ProcessingFeeOverride?.fieldKey,
  validation: moneyValidation
});
export const policySection = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.Exceptions.PolicySection?.fieldKey,
  validation: multiSelectValidation
});
export const exitStrategy = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ExitStrategy?.fieldKey,
  validation: stringValidation
});

export const targetCloseDate = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.TargetCloseDate?.fieldKey,
  validation: dateValidation
});
export const applicationRequestedLoanAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.ApplicationRequestedLoanAmount?.fieldKey,
  validation: moneyValidation
});

export const projectAnticipatedCompletionTimeline = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.ProjectAnticipatedCompletionTimeline?.fieldKey,
  validation: stringValidation
});

export const constructionBudgetRemainingAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.ConstructionBudgetRemainingAmount?.fieldKey,
  validation: moneyValidation
});

export const verifiableConstructionCompletedAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.VerifiableConstructionCompletedAmount?.fieldKey,
  validation: moneyValidation
});

export const lienDollarAmount = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LienDollarAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyTypeChangingIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.PropertyTypeChangingIndicator?.fieldKey,
  validation: booleanValidation
});

export const propertyZoningChangesIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyZoningChangesIndicator?.fieldKey,
  validation: booleanValidation
});

export const lenderPropertyInsuranceProviderIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.LenderPropertyInsuranceProviderIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const lenderTitleProviderIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LenderTitleProviderIndicator?.fieldKey,
  validation: booleanValidation
});

export const hoaContactName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.HOAContactName?.fieldKey,
  validation: stringValidation
});

export const hoaContactPhone = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.HOAContactPhone?.fieldKey,
  validation: stringValidation
});

export const hoaContactEmail = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.HOAContactEmail?.fieldKey,
  validation: emailValidation
});

export const occupancy = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.Occupancy?.fieldKey,
  validation: percentValidation
});

export const subdividePropertyPartialReleasesIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.SubdividePropertyPartialReleasesIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const propertyRentReadyConditionIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyRentReadyConditionIndicator?.fieldKey,
  validation: booleanValidation
});

export const propertyLeasePurchaseOptionIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyLeasePurchaseOptionIndicator?.fieldKey,
  validation: booleanValidation
});

export const occupationIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.OccupationIndicator?.fieldKey,
  validation: booleanValidation
});

export const sellerRelationshipIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.SellerRelationshipIndicator?.fieldKey,
  validation: booleanValidation
});

export const associatedPropertiesDescription = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.AssociatedPropertiesDescription?.fieldKey,
  validation: stringValidation
});

export const developmentStrategy = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.DevelopmentStrategy?.fieldKey,
  validation: stringValidation
});

export const lotStatus = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LotStatus?.fieldKey,
  validation: stringValidation
});

export const currentLotMarketValueAmount = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.CurrentLotMarketValueAmount?.fieldKey,
  validation: moneyValidation
});

export const lotZonedParcelIdIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LotZonedParcelIdIndicator?.fieldKey,
  validation: booleanValidation
});

export const buildingPermitsIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.BuildingPermitsIndicator?.fieldKey,
  validation: booleanValidation
});

export const buildingPermitsExpectedDate = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.BuildingPermitsExpectedDate?.fieldKey,
  validation: dateValidation
});

export const lotUtilitiesIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LotUtilitiesIndicator?.fieldKey,
  validation: booleanValidation
});

export const buildingEnvelopeExpandedIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.BuildingEnvelopeExpandedIndicator?.fieldKey,
  validation: booleanValidation
});

export const loadBearingWallsRemoveIntendedIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.LoadBearingWallsRemoveIntendedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const squareFootageAddedIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.SquareFootageAddedIndicator?.fieldKey,
  validation: booleanValidation
});

export const generalContractorFullName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.GeneralContractor.FullName?.fieldKey,
  validation: stringValidation
});

export const generalContractorRepresentativeFirstName = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.GeneralContractor.RepresentativeFirstName
      ?.fieldKey,
  validation: stringValidation
});

export const generalContractorRepresentativeLastName = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.GeneralContractor.RepresentativeLastName
      ?.fieldKey,
  validation: stringValidation
});

export const generalContractorContactPointTelephone = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.GeneralContractor.ContactPointTelephoneValue
      ?.fieldKey,
  validation: stringValidation
});

export const generalContractorContactPointEmail = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.GeneralContractor.ContactPointEmailValue
      ?.fieldKey,
  validation: emailValidation
});

export const brokerRepresentativeFirstName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.Broker.RepresentativeFirstName.fieldKey,
  validation: stringValidation
});

export const brokerRepresentativeLastName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.Broker.RepresentativeLastName.fieldKey,
  validation: stringValidation
});

export const brokerFullName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.Broker.FullName.fieldKey,
  validation: stringValidation
});

export const brokerContactPointEmailValue = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.Broker.ContactPointEmailValue.fieldKey,
  validation: emailValidation
});

export const brokerContactPointTelephone = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.Broker.ContactPointTelephoneValue.fieldKey,
  validation: stringValidation
});

export const nmlsIdentifier = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.Broker.NMLSIdentifier.fieldKey,
  validation: stringValidation
});

export const brokerWiringInstructionsIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.Broker.BrokerWiringInstructionsIndicator
      .fieldKey,
  validation: booleanValidation
});

export const lenderFinancePartnerFirstName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LenderFinance.PartnerFirstName.fieldKey,
  validation: stringValidation
});

export const lenderFinancePartnerLastName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LenderFinance.PartnerLastName.fieldKey,
  validation: stringValidation
});

export const lenderFinancePartnerInitialTermSheetApprovalStatusIndicator =
  buildInput({
    fieldKey:
      limaSpecs.deal?.entitySpecs.LenderFinance
        .PartnerInitialTermSheetApprovalStatusIndicator.fieldKey,
    validation: booleanValidation
  });

export const lenderFinancePartnerInitialTermSheetApprovalStatusDate =
  buildInput({
    fieldKey:
      limaSpecs.deal?.entitySpecs.LenderFinance
        .PartnerInitialTermSheetApprovalStatusDate.fieldKey,
    validation: dateValidation
  });

export const lenderFinancePartnerFinalTermSheetApprovalStatusDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.LenderFinance
      .PartnerFinalTermSheetApprovalStatusDate.fieldKey,
  validation: dateValidation
});

export const lenderFinanceBorrowerFinalTermSheetApprovalStatusDate = buildInput(
  {
    fieldKey:
      limaSpecs.deal?.entitySpecs.LenderFinance
        .BorrowerFinalTermSheetApprovalStatusDate.fieldKey,
    validation: dateValidation
  }
);

export const defaultInterestRatePercent = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.DefaultInterestRatePercent.fieldKey,
  validation: percentValidation
});

export const releasePercent = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.ReleasePercent.fieldKey,
  validation: percentValidation
});

export const originationFeeAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.OriginationFeeAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const deferredOriginationFeeAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.DeferredOriginationFeeAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const processingFeeAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.ProcessingFeeAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const buyDownFeeAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.BuyDownFeeAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const buyUpFeeReductionAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.BuyUpFeeReductionAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const brokerOriginationFeeAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.BrokerOriginationFeeAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const brokerProcessingFeeAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.BrokerProcessingFeeAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const totalBrokerFeeAmount = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.aggregations.TotalBrokerFeeAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const interestReserveEscrow = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.InterestReserveEscrow
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const servicingSetupFee = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.ServicingSetupFee?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderFinanceProcessingFee = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.LenderFinanceProcessingFee
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderFinanceApplicationFee = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.LenderFinanceApplicationFee
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderFinanceDocumentFee = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.LenderFinanceDocumentFee
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderFinanceAppraisalFee = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.LenderFinanceAppraisalFee
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const propertyInsuranceAgencyFullName = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PropertyInsuranceAgency.FullName?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceAgencyFullName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.FloodInsuranceAgency.FullName?.fieldKey,
  validation: stringValidation
});

export const deferredMaintenanceAmount = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.DeferredMaintenanceAmount?.fieldKey,
  validation: moneyValidation
});

export const titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicator =
  buildInput({
    fieldKey:
      limaSpecs.deal?.entitySpecs.TitleCommitment
        .AllPayoffsLiensIncludedInSection1RequirementsIndicator?.fieldKey,
    validation: booleanValidation
  });

export const titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator =
  buildInput({
    fieldKey:
      limaSpecs.deal?.entitySpecs.TitleCommitment
        .RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator?.fieldKey,
    validation: booleanValidation
  });

export const titleCommitmentTitleInternationalExecutionIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.TitleCommitment
      .TitleInternationalExecutionIndicator?.fieldKey,
  validation: booleanValidation
});

export const titleCommitmentLeaseholdEndorsementIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.TitleCommitment.LeaseholdEndorsementIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const totalLiquidAssetAmount = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.TotalLiquidAssetAmount?.fieldKey,
  validation: moneyValidation
});

export const settlementStatementMatchLedgerIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.SettlementStatementMatchLedgerIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const loanAmountVerifiedMatchIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.LoanAmountVerifiedMatchIndicator?.fieldKey,
  validation: booleanValidation
});

export const constructionBudgetVerifiedMatchIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.ConstructionBudgetVerifiedMatchIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const eoNamedInsuredMatchIndicator = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.EONamedInsuredMatchIndicator?.fieldKey,
  validation: booleanValidation
});

export const wireInsuranceMatchDealIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.WireInsuranceMatchDealIndicator?.fieldKey,
  validation: booleanValidation
});

export const fundingShieldStatusType = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.FundingShieldStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const postClosingNotes = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.PostClosingNotes?.fieldKey,
  validation: stringValidation
});

export const personalGuarantorsCount = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.PersonalGuarantorsCount?.fieldKey,
  validation: numberValidation
});

export const powerOfAttorneyFullName = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.PowerOfAttorneyFullName?.fieldKey,
  validation: stringValidation
});

export const promissoryNoteRequestedIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PromissoryNoteRequestedIndicator?.fieldKey,
  validation: booleanValidation
});

export const promissoryNoteShippedIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.PromissoryNoteShippedIndicator?.fieldKey,
  validation: booleanValidation
});

export const promissoryNoteTrackingNumber = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.PromissoryNoteTrackingNumber?.fieldKey,
  validation: stringValidation
});

export const allongeAssignmentSentToWarehouseIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.AllongeAssignmentSentToWarehouseIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const allongeAssignmentTrackingNumber = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.AllongeAssignmentTrackingNumber?.fieldKey,
  validation: stringValidation
});

export const fundingAndDisbursementApprovalIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.FundingAndDisbursementApprovalIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const achRequiredIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.AchRequiredIndicator?.focused
      ?.fieldKey,
  validation: booleanValidation,
  calculationType: AggregationType.ThirdParty
});

export const achExemptionStatus = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.quote.aggregations.AchExemptionStatus?.focused
      ?.fieldKey,
  validation: booleanValidation,
  calculationType: AggregationType.ThirdParty
});

export const loanPackageChecksReceivedIndicator = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.LoanPackageChecksReceivedIndicator?.fieldKey,
  validation: booleanValidation
});

export const loanPackageCheckNumber = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LoanPackageCheckNumber?.fieldKey,
  validation: stringValidation
});

export const loanPackageCheckAmount = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.LoanPackageCheckAmount?.fieldKey,
  validation: moneyValidation
});

export const recordedMortgageReceivedDate = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.RecordedMortgageReceivedDate?.fieldKey,
  validation: dateValidation
});

export const titlePolicyReceivedDate = buildInput({
  fieldKey: limaSpecs.deal?.entitySpecs.TitlePolicyReceivedDate?.fieldKey,
  validation: dateValidation
});

export const recordedWarrantyDeedReceivedDate = buildInput({
  fieldKey:
    limaSpecs.deal?.entitySpecs.RecordedWarrantyDeedReceivedDate?.fieldKey,
  validation: dateValidation
});
