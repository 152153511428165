import { PosDealUserRole } from "@elphi/types";

export const posDealUserRoleOptions: {
  [p in PosDealUserRole]: string;
} = {
  [PosDealUserRole.PrimaryGuarantor]: "Primary Guarantor",
  [PosDealUserRole.Guarantor]: "Guarantor",
  [PosDealUserRole.Signatory]: "Signatory",
  [PosDealUserRole.SignatoryViaCorprateResolution]:
    "Signatory via Corprate Resolution",
  [PosDealUserRole.Applicant]: "Applicant"
};
